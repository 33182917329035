import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";
import $ from "jquery";

import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../Redux";
import { bindActionCreators } from "redux";
import jwtDecode from "jwt-decode";

function RegisterFormNew(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    gers: "",
    store_location: "",
    name: "",
    lname: "",
    emailid: "",
    phone: "",
    checkbox: "",
  });

  const [errors, setErrors] = useState({});
  const [resetKey, setResetKey] = useState(0);

  const [storeId, setStoreId] = useState([]);
  const [captcha, setCaptcha] = useState(false);
  const [gersValid, setGersValid] = useState(false);
  const recaptchaRef = useRef(null);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { rsaAccessToken, rsa_id } = state;

  useEffect(() => {
    if ((rsaAccessToken, rsa_id)) {
      setShow(true);
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const decoded = jwtDecode(rsaAccessToken);
      if (currentTime > decoded.exp) {
        toast.error("RSA request time out!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        userActions.logOut("rsaLogOut");
        setTimeout(() => {
          window.location.href = "/register-login";
        }, 500);
      }
    } else {
      window.location.href = "/register-login";
    }
  }, [rsaAccessToken, rsa_id]);

  useEffect(() => {
    async function getStoreLocation() {
      let errors = {};

      try {
        //FIXME remove this service if not used
        const response = await RegisterService.getStoreLocation();
        let value = response.data.data;
        const results = [];
        value.map((value) => {
          return results.push({
            value: value.store_location,
            label: value.store_location,
          });
        });
        await setStoreId([...results]);

        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    // if (localStorage.getItem("return_page") !== "true") {
    //   navigate("/register", { replace: true });
    // } else {
    // }
    getStoreLocation();
  }, []);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "gers") {
      setGersValid(false);
      if (value.length >= 3) {
        const responseuserform = RegisterService.checkGers(value);
        responseuserform
          .then((response) => {
            setGersValid(true);
            saveErrors([]);
          })
          .catch((err) => {
            console.log(err);
            setGersValid(false);

            let errorData = {};
            errorData["gers"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.gers &&
              err?.response?.data?.message.gers[0];

            saveErrors(errorData);
            setTimeout(() => {
              ref?.current?.focus();
            }, 200);
          });
      }
    }
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        gers: fields?.gers,
        store_location: fields?.store_location,
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        phone: fields?.phone,
        choice: fields?.checkbox,
        userId: rsa_id,
        captcha: captcha,
      };

      try {
        const responseuserform = RegisterService.regDataNew(data);
        responseuserform
          .then((response) => {
            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);

            toast.success("Registered Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              setFields({
                gers: "",
                store_location: "",
                name: "",
                lname: "",
                emailid: "",
                phone: "",
                checkbox: "",
              });
              setCaptcha(false);
              setErrors([]);
              setResetKey((prevKey) => prevKey + 1);
              $("input:radio").prop("checked", false);
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              // window.location.reload();
            }, 1000);

            // Swal.fire({
            //   title: "Successful!",
            //   text: "Form Submitted!",
            //   type: "success",
            //   icon: "success",
            // }).then((result) => {
            //   secureLocalStorage.setItem(
            //     "registerform",
            //     JSON.stringify({
            //       gers: fields.gers,
            //       store_location: fields.store_location,
            //       name: fields.name,
            //       lname: fields.lname,
            //       emailid: fields.emailid,
            //       phone: fields.phone,
            //       choice: fields?.checkbox,
            //     })
            //   );
            //   localStorage.removeItem("return_page");
            //   localStorage.setItem("isRegister", true);
            //   navigate("/register/registration-thankyou");
            // });

            // if (value_userform?.includes("redirect")) {
            // }
          })
          .catch((err) => {
            console.log(err);

            if (err?.response?.status === 400) {
              toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setTimeout(() => {
                logOut();
              }, 1500);
            } else {
              let errorData = {};
              errorData["gers"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.gers &&
                err?.response?.data?.message.gers[0];
              errorData["store_location"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.store_location &&
                err?.response?.data?.message.store_location[0];
              errorData["name"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.fname &&
                err?.response?.data?.message.fname[0];
              errorData["lname"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.lname &&
                err?.response?.data?.message.lname[0];
              errorData["emailid"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.email &&
                err?.response?.data?.message.email[0];
              errorData["phone"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.phone &&
                err?.response?.data?.message.phone[0];
              errorData["checkederrortwo"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.size &&
                err?.response?.data?.message.size[0];
              errorData["checkbox"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.choice &&
                err?.response?.data?.message.choice[0];
              errorData["checkederrorcaptcha"] =
                err?.response?.data?.message &&
                err?.response?.data?.message.captcha &&
                err?.response?.data?.message.captcha[0];

              saveErrors(errorData);
              setTimeout(() => {
                ref?.current?.focus();
              }, 200);
            }
          });
      } catch (err) {
        alert(err);
        if (err.response.status === 429 || err.response.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["gers"] = err?.response?.data?.message.gers[0];
        errorData["store_location"] =
          err?.response?.data?.message.store_location[0];
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];
        errorData["checkbox"] = err?.response?.data?.message.choice[0];
        errorData["checkederrorcaptcha"] =
          err?.response?.data?.message.captcha[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let isformIsValid = true;

    return isformIsValid;
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const logOut = async () => {
    await userActions.logOut("rsaLogOut");
    navigate("/register-login", { replace: true });
  };
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {show ? (
        <div className="container-fluid">
          <div className="position-relative">
            <button onClick={logOut} className="logout-btn-reg">
              Logout
            </button>
          </div>
          {/* <HeaderInner/> */}
          <HeaderLower />
          <div id="main-registration-container">
            <div className="white-box form" id="font-style">
              <h1>Register to receive your free reward card!</h1>
              <hr />
              <form
                name="userRegistrationForm"
                className="form-outer"
                onSubmit={submituserRegistrationForm}
                // onClick={this.popup}
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="formsub">
                      GERS Initials <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter GERS Initials"
                      style={errors.gers ? { border: "1px solid red" } : null}
                      name="gers"
                      id="gers"
                      className="searchBox_deals vc-validate"
                      ref={errors.gers ? ref : null}
                      value={fields.gers || ""}
                      autoFocus=""
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === "Tab") {
                          e.preventDefault();
                          const selectInput = document.querySelector(
                            ".react-select__input"
                          );
                          if (selectInput) {
                            selectInput.focus();
                          }
                        }
                      }}
                    />
                    <div className="errorMsg">{errors.gers}</div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Store Code & Location <span className="red_point">*</span>
                    </label>

                    <Select
                      key={resetKey}
                      classNamePrefix="react-select"
                      className={`select_1 dropdown ${
                        errors.store_location && !errors.gers
                          ? "error-select-search"
                          : ""
                      }`}
                      placeholder="Select Store Code & Location"
                      options={storeId}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={!gersValid}
                      name="store_location"
                      ref={errors.store_location && !errors.gers ? ref : null}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["store_location"]: selectedOption.value,
                            };
                          });
                        } else {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["store_location"]: "",
                            };
                          });
                        }
                      }}
                    />

                    <div className="errorMsg">
                      {errors.store_location && !errors.gers
                        ? errors.store_location
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer First Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      disabled={!gersValid}
                      style={
                        errors.name && !errors.store_location && !errors.gers
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      name="name"
                      id="fname"
                      className="searchBox_deals vc-validate"
                      ref={
                        errors.name && !errors.store_location && !errors.gers
                          ? ref
                          : null
                      }
                      value={fields.name || ""}
                      autoFocus=""
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.name && !errors.store_location && !errors.gers
                        ? errors.name
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Last Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="lname"
                      disabled={!gersValid}
                      style={
                        errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? { border: "1px solid red" }
                          : null
                      }
                      placeholder="Enter Last Name"
                      id="lname"
                      className="searchBox_deals vc-validate"
                      value={fields.lname || ""}
                      ref={
                        errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? ref
                          : null
                      }
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.gers
                        ? errors.lname
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Email Address{" "}
                      <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailid"
                      disabled={!gersValid}
                      ref={
                        errors.emailid &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? ref
                          : null
                      }
                      style={
                        errors.emailid &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      id="emailid"
                      placeholder="Enter Email Address"
                      value={fields.emailid || ""}
                      autoFocus=""
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.gers
                        ? errors.emailid
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="formsub">
                      Customer Phone#
                      <span className="red_point"></span>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      disabled={!gersValid}
                      ref={
                        errors.phone &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? ref
                          : null
                      }
                      style={
                        errors.phone &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      placeholder="Enter Phone#"
                      value={fields.phone || ""}
                      autoFocus=""
                      onChange={handleChange}
                      onKeyUp={onlyNumbers}
                      onPaste={pasted}
                      maxLength={10}
                    />
                    <div className="errorMsg">
                      {errors.phone &&
                      !errors.emailid &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.gers
                        ? errors.phone
                        : null}
                    </div>
                  </div>

                  <div className="col-md-6 mt-2">
                    <label>
                      <input
                        type="radio"
                        name="checkbox"
                        className="conditions"
                        value="The Test Rest Reward Only"
                        onChange={handleChange}
                        disabled={!gersValid}
                      />
                      <span>Register for the Test Rest Reward Only</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="checkbox"
                        className="conditions"
                        value="The Trip Contest Only"
                        onChange={handleChange}
                        disabled={!gersValid}
                      />
                      <span>Register for the Trip Contest Only</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="checkbox"
                        className="conditions"
                        value="The Test Rest and Trip Contest"
                        onChange={handleChange}
                        disabled={!gersValid}
                      />
                      <span>Register for the Test Rest and Trip Contest</span>
                      <div className="errorMsg">
                        {errors.checkbox &&
                        !errors.phone &&
                        !errors.emailid &&
                        !errors.name &&
                        !errors.store_location &&
                        !errors.gers
                          ? errors.checkbox
                          : null}
                      </div>
                    </label>
                  </div>

                  <div className="col-md-6 mt-2">
                    <div className="overflow-hider">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                        onChange={onCaptchaChange}
                        ref={recaptchaRef}
                      />

                      {!gersValid && <div className="captcha-overlay"></div>}
                    </div>
                    <div className="errorMsg">
                      {" "}
                      {errors.checkederrorcaptcha &&
                      !errors.checkbox &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.gers
                        ? errors.checkederrorcaptcha
                        : null}
                    </div>
                  </div>
                </div>

                <div className="alert alert-info">
                  Limit 1 per household.
                  {/* , while supplies last. */} Some restrictions apply, see
                  store for detail.
                </div>
                <input
                  type="submit"
                  name="submit"
                  id="submit"
                  value="SUBMIT"
                  disabled={!gersValid}
                  className="sub-btn2 button Formfirst"
                />
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="fix-white d-block"></div>
      )}
      <Footer2 />
    </>
  );
}
export default RegisterFormNew;
