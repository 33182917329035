import axios from "axios";
import { Buffer } from "buffer";
const API_URL = process.env.REACT_APP_API_Link;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const getStates = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStates",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const getEmployees = (store) => {
  return axios({
    method: "GET",
    url: API_URL + "getEmployees/" + store,
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const getStoreLocation = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStoreLocation",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const regData = (bodyFormData) => {
  return axios({
    method: "POST",
    url: API_URL + "registerDataRaymour",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const regDataNew = (bodyFormData) => {
  return axios({
    method: "POST",
    url: API_URL + "registerDataRaymourBeta",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const checkEmployee = (store, emp) => {
  return axios({
    method: "GET",
    url: API_URL + "checkEmployee/" + store + "/" + emp,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const checkLoginToken = (tokenVal) => {
  return axios({
    method: "GET",
    url: API_URL + "check-token-expiration/" + tokenVal,
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const setPassword = (values) => {
  return axios({
    method: "POST",
    url: API_URL + "setup-password",
    data: {
      password: values.password,
      password_confirmation: values.con_password,
      token: values.token,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const loginRsa = (values) => {
  return axios({
    method: "POST",
    url: API_URL + "register-login",
    data: {
      password: values.password,
      username: values.username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const forgotRsa = (values) => {
  return axios({
    method: "POST",
    url: API_URL + "forgot-password",
    data: {
      email: values.username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const checkGers = (gers) => {
  return axios({
    method: "GET",
    url: API_URL + "check-gers-exists/" + gers,
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const signUpRsa = (values) => {
  return axios({
    method: "POST",
    url: API_URL + "register-signup",
    data: {
      password: values.password,
      email: values.email,
      password_confirmation: values.confirm_password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const checkRegisterToken = (tokenVal) => {
  return axios({
    method: "POST",
    url: API_URL + "verify-email-signup",
    data: {
      token: tokenVal,
    },
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const RegisterService = {
  getStates,
  regData,
  getEmployees,
  regDataNew,
  getStoreLocation,
  checkEmployee,

  checkLoginToken,
  setPassword,
  loginRsa,
  forgotRsa,
  checkGers,
  signUpRsa,
  checkRegisterToken,
};

export default RegisterService;
