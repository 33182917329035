import React, { useState } from "react";
import "./SignUp.css";
import logo from "../../Images/logo.webp";
import { useFormik } from "formik";
import { signUpRsa } from "../../schema";
import { Link, useNavigate } from "react-router-dom";
import RegisterService from "../../services/register.service";
import { toast } from "react-toastify";
export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loginCheck = async (values, action) => {
    setLoading(true);
    try {
      const response = await RegisterService.signUpRsa(values);

      toast.success("Registration successful. Check your email.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/register-login", { replace: true });
      }, 1500);
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 401) {
        toast.error("Username or Password is invalid!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: signUpRsa,
    onSubmit: (values, action) => {
      loginCheck(values, action);
    },
  });

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="login100-more">
          <img src={logo} alt="logo-img" />
        </div>
        <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
          <form className="login100-form " onSubmit={handleSubmit} noValidate>
            <span className="login100-form-title p-b-59">Sign Up</span>

            <div
              className={`wrap-input100   ${
                errors.email && touched.email
                  ? "alert-validate"
                  : !errors.email && touched.email
                  ? "true-validate"
                  : ""
              }`}
              data-validate={errors.email}
            >
              <span className="label-input100">Email</span>
              <input
                className={`input100 ${values.email ? "has-val" : ""}`}
                type="text"
                onChange={handleChange}
                name="email"
                value={values.email || ""}
                placeholder="Email"
              />
              <span className="focus-input100"></span>
            </div>

            <div
              className={`wrap-input100   ${
                errors.password && touched.password
                  ? "alert-validate"
                  : !errors.password && touched.password
                  ? "true-validate"
                  : ""
              }`}
              data-validate={errors.password}
            >
              <span className="label-input100">Password</span>
              <input
                className={`input100 ${values.password ? "has-val" : ""}`}
                type="password"
                onChange={handleChange}
                name="password"
                value={values.password || ""}
                placeholder="*************"
              />
              <span className="focus-input100"></span>
            </div>
            <div
              className={`wrap-input100   ${
                errors.confirm_password && touched.confirm_password
                  ? "alert-validate"
                  : !errors.confirm_password && touched.confirm_password
                  ? "true-validate"
                  : ""
              }`}
              data-validate={errors.confirm_password}
            >
              <span className="label-input100">Confirm Password</span>
              <input
                className={`input100 ${
                  values.confirm_password ? "has-val" : ""
                }`}
                type="password"
                onChange={handleChange}
                name="confirm_password"
                value={values.confirm_password || ""}
                placeholder="*************"
              />
              <span className="focus-input100"></span>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button className="login100-form-btn" type="submit">
                  Sign Up
                </button>
              </div>
              <Link
                to="/register-login"
                className="dis-block txt3 hov1 p-r-30 p-t-10 p-b-10 p-l-30"
              >
                Sign in
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
