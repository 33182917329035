import React, { useEffect, useState } from "react";
import InnerHTML from "dangerously-set-html-content";

import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

import fileImg from "../AdminSingle/images/file.png";
import browse from "../AdminSingle/images/icon-4.png";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import Swal from "sweetalert2";
import $ from "jquery";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

export default function ImportRsa() {
  const [loading, setLoading] = useState("0%");
  const [actionLoading, setActionLoading] = useState(true);

  const [duplicateMsg, setduplicateMsg] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const [emailStatus, setEmailStatus] = useState([]);

  const [fileError, setFileError] = useState(false);
  const [file, setFile] = useState("");
  const [fileStatus, setFileStatus] = useState("");
  const state = useSelector((state) => state.stateVals);

  const { admin_id, adminAccessToken } = state;
  const putCustomers = async (event) => {
    setFileStatus("");

    if (!event.target.files?.length) {
      setFileError(true);
      setLoading("0%");
    } else {
      setFile(event.target.files[0]);
      setFileError(false);
      await setLoading(`0%`);
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", event.target.files[0].name);
      formData.append("AdminId", admin_id);

      try {
        let randomNum = Math.floor(Math.random() * 10);
        await setLoading(`${randomNum}%`);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (20 - 10 + 1)) + 10;
          setLoading(`${randomNum}%`);
        }, 200);
        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
          setLoading(`${randomNum}%`);
        }, 400);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (70 - 40 + 1)) + 40;
          setLoading(`${randomNum}%`);
        }, 500);

        const response = await AdminListService.uploadRsas(formData);

        await setTimeout(() => {
          if (response.status === 200) {
            setErrorData([]);
            setduplicateMsg("");
            setInvalidEmails([]);

            setLoading("100%");
            toast.success("Uploaded Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              setFileStatus("ok");
              rsaList("1");
            }, 500);
          }
        }, 200);
      } catch (err) {
        console.log(err);

        await setTimeout(() => {
          const randomNum = Math.floor(Math.random() * (80 - 70 + 1)) + 70;
          setLoading(`${randomNum}%`);
        }, 200);
        await setTimeout(() => {
          setLoading("100%");
          rsaList("1");
        }, 500);

        await setTimeout(() => {
          if (
            err?.response?.data?.data?.invalidEmails?.invalidEmail.length &&
            Array.isArray(
              err?.response?.data?.data?.invalidEmails?.invalidEmail
            )
          ) {
            const errors = [];

            err?.response?.data?.data?.invalidEmails?.invalidEmail.map(
              (value) => {
                return errors.push({
                  row: value.row,
                  errors: value,
                });
              }
            );

            setInvalidEmails([...errors]);

            toast.error("Invalid Emails Found!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (err?.response?.data?.data) {
            setInvalidEmails([]);
            if (
              err?.response?.data?.data?.failure?.length > 0 &&
              err?.response?.data?.data?.duplicate?.dataDup?.length === 0
            ) {
              if (Array.isArray(err?.response?.data?.data?.failure)) {
                const errors = [];

                err?.response?.data?.data?.failure.map((value) => {
                  return errors.push({
                    row: value.row,
                    errors: value.errors[0].toString().replaceAll("_", " "),
                  });
                });

                setErrorData([...errors]);
                setduplicateMsg("");
              }
            } else {
              setduplicateMsg(err?.response?.data?.data?.message);
              setErrorData([]);
            }
          } else if (err?.response?.data) {
            setInvalidEmails([]);
            if (err?.response?.data?.failure !== "") {
              if (Array.isArray(err?.response?.data?.failure)) {
                setduplicateMsg(err?.response?.data?.failure[0]);
              }
            }
          } else {
            {
              setInvalidEmails([]);

              toast.error("Error in uploading Rsa", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }

          setFileStatus("err");
        }, 700);
      }
    }
  };

  const Duplicate = ({ data }) => {
    return (
      <>
        {data !== "" ? (
          <div className="alert alert-danger  mb-2 p-2 rounded-1">
            <h5 className="m-0 fw-bold">Error!</h5>{" "}
            <InnerHTML html={data ? data : ""} />
          </div>
        ) : null}
      </>
    );
  };

  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const rsaList = async (page) => {
    try {
      setActionLoading(true);
      const responce = await AdminListService.listRsa(page);
      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            AccId: value.id,
            AccName: value.fname + " " + value.lname,

            email_status: value.email_status,
            UserName: value.username,
            Password: value.password,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);
        setActionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setActionLoading(false);

      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      rsaList("1");
    }
  }, [adminAccessToken]);

  const delAcc = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deleteRsa(delId);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              rsaList("1");
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.AccName.toString().toLowerCase().includes(searchQuery) ||
          item.Password.toString().toLowerCase().includes(searchQuery) ||
          item.email_status.toString().toLowerCase().includes(searchQuery) ||
          item.UserName.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };

  const sendMailUserById = async (id) => {
    setActionLoading(true);
    try {
      const responce = await AdminListService.sendMailToRsaById(id);

      if (responce.status === 200) {
        setActionLoading(false);
      }

      setEmailStatus((prevEmailStatus) => ({
        ...prevEmailStatus,
        [id]: "Email sent successfully",
      }));
      toast.success("Email sent successfully!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      console.log(err);
      setActionLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        setEmailStatus((prevEmailStatus) => ({
          ...prevEmailStatus,
          [id]: err?.response?.data?.message,
        }));
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setEmailStatus((prevEmailStatus) => ({
          ...prevEmailStatus,
          [id]: "Error in sending email",
        }));
        toast.error("Error in sending email", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("AccId")}
                className={getClassNamesFor("AccId")}
              >
                No.
              </th>
              <th
                scope="col"
                onClick={() => requestSort("UserName")}
                className={getClassNamesFor("UserName")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("AccName")}
                className={getClassNamesFor("AccName")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Password")}
                className={getClassNamesFor("Password")}
              >
                Password
              </th>

              <th
                scope="col"
                onClick={() => requestSort("email_status")}
                className={getClassNamesFor("email_status")}
              >
                Email Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Active")}
                className={getClassNamesFor("Active")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {page === 1 ? index + 1 : (page - 1) * limit + index + 1}
                  </td>
                  <td>{el.UserName}</td>
                  <td>{el.AccName}</td>
                  <td>{el.Password !== "" ? el.Password : "N/A"}</td>
                  <td
                    className={`text-capitalize ${
                      el.email_status === "sent"
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    {el.email_status}
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        to={`/users-by-rsa/${el.AccId}`}
                        className="btn btn-sm btn-outline-success"
                      >
                        <i className="bi bi-eye-fill"></i> View
                      </Link>
                      <Link
                        to={`/edit-rsa/${el.AccId}`}
                        className="btn btn-sm btn-outline-primary"
                      >
                        <i className="bi bi-pencil"></i> Edit
                      </Link>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        title="De Activate"
                        onClick={() => {
                          delAcc(el.AccId);
                        }}
                      >
                        <i className="bi bi-trash-fill"></i> Delete
                      </button>

                      {el.Password !== "" &&
                      el.email_status === "sent" ? null : !emailStatus[
                          el.AccId
                        ] ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          title="Edit"
                          onClick={() => {
                            sendMailUserById(el.AccId);
                          }}
                        >
                          <i className="bi bi-envelope-fill"></i> Send Email
                        </button>
                      ) : (
                        <strong>{emailStatus[el.AccId]}</strong>
                      )}
                    </div>
                    {/* <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="text-success"
                        to={`/users-by-rsa/${el.AccId}`}
                      >
                        <i className="bi bi-eye-fill"></i>
                      </Link>
                      <button
                        type="button"
                        onClick={() => {
                          delAcc(el.AccId);
                        }}
                        className="text-danger open-model-btn"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </div> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="single-admin" style={{ minHeight: "auto" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-7 col-lg-9 d-flex align-items-center">
                        <h4 className="inner-heading mb-0">
                          Upload Multiple RSA
                        </h4>
                      </div>
                      <div className="col-md-5 col-lg-3">
                        <a
                          className="btn btn-primary form-btn d-flex align-items-center justify-content-center mt-0"
                          href="/assets/csv/sample-sheet-register-login.xlsx"
                        >
                          Download Sample Sheet
                        </a>
                      </div>
                    </div>

                    <div className="drop_box w-100">
                      <img src={fileImg} className="file-icon" alt="missing" />

                      <p>Select (.CSV, .XLS, .XLSX) File</p>
                      <input
                        type="file"
                        accept=".xlsx,.xls,.csv"
                        id="fileID"
                        onChange={putCustomers}
                        onClick={(e) => {
                          e.target.value = null;
                          setLoading("0%");
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          const dropArea = document.querySelector(".drop_box"),
                            input = dropArea.querySelector("input");

                          input.click();
                        }}
                      >
                        <img src={browse} alt="missing" />
                        Browse
                      </button>
                    </div>
                    {loading !== "0%" ? (
                      fileStatus === "" ? (
                        fileError ? (
                          <div className="progress-file mt-2">
                            <div className="col-lg-12">
                              <div className="alert alert-danger mb-2 p-2 rounded-1">
                                <b className="fw-bold">Error! </b> Please Select
                                File
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="progress-file">
                            <h6>Uploading</h6>
                            <p>
                              {file.name}{" "}
                              <span>{Math.round(file.size / 1024)}KB</span>
                              {/* <button className="btn btn-link">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-x"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                  </button> */}
                            </p>
                            <div className="clearfix"></div>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: loading }}
                              ></div>
                            </div>
                            <div className="done-perc">
                              {loading} Done
                              {/* <span>120kb / sec</span> */}
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        )
                      ) : fileStatus === "ok" ? (
                        <></>
                      ) : (
                        // <div className="progress-file mt-2">
                        //   <div className="col-lg-12">
                        //     <div className="alert alert-success alert-dismissible fade show mb-2 p-2 rounded-1">
                        //       Uploaded Successfully!
                        //       <button
                        //         type="button"
                        //         className="btn-close p-2"
                        //         data-bs-dismiss="alert"
                        //         aria-label="Close"
                        //       ></button>
                        //     </div>
                        //   </div>
                        // </div>
                        <div className="progress-file mt-2">
                          <div className="row">
                            {invalidEmails.length
                              ? invalidEmails.map((res, index) => {
                                  return (
                                    <div className="col-lg-6">
                                      <div
                                        className="alert alert-danger alert-dismissible fade show  mb-2 p-2 rounded-1"
                                        key={index}
                                      >
                                        <strong className="fw-bold">
                                          Invalid Emails:{" "}
                                        </strong>
                                        {res.errors}
                                        <button
                                          type="button"
                                          className="btn-close p-2"
                                          data-bs-dismiss="alert"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}

                            {errorData.length
                              ? errorData.map((res, index) => {
                                  return (
                                    <div className="col-lg-6">
                                      <div
                                        className="alert alert-danger alert-dismissible fade show mb-2 p-2 rounded-1"
                                        key={index}
                                      >
                                        <b className="fw-bold">Note!</b> Problem
                                        in row number {res.row} ,
                                        <b className="fw-bold">Error! </b>
                                        {res.errors}
                                        <button
                                          type="button"
                                          className="btn-close p-2"
                                          data-bs-dismiss="alert"
                                          aria-label="Close"
                                        ></button>
                                      </div>{" "}
                                    </div>
                                  );
                                })
                              : null}
                            <div className="col-lg-6">
                              <Duplicate data={duplicateMsg} />
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage RSA </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${actionLoading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
