import { React, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import RegisterService from "../../services/register.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setPassword } from "../../schema";

export default function VarifyEmail() {
  const [loading, setLoading] = useState("checking");
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const { token } = useParams();
  useEffect(() => {
    if (token) {
      const checkToken = async () => {
        try {
          const response = await RegisterService.checkRegisterToken(token);
          if (response.status === 200) {
            toast.success("Email verification completed successfully.", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              navigate("/register-login", { replace: true });
            }, 1500);
          }
        } catch (err) {
          setLoading("fail");
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Email verification failed.", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setTimeout(() => {
            if (err?.response?.status === 409) {
              window.location.href = "/register-login";
            } else {
              window.location.href = "/signup";
            }
          }, 1500);
        }
      };
      checkToken();
    } else {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {loading === "checking"
            ? " Checking..."
            : loading == "ok"
            ? "Set Password"
            : "Invalid Link"}
        </title>
      </Helmet>

      {loading === "checking" ? (
        <div className="fix-white">
          <div className={`loader in `}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
